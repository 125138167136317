import React from "react"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "../styles/main.scss"

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <h2 className='app__subtitle display-2'>Coming soon</h2>
        <p>An online platform to facilitate the use of Restorative Justice.</p>
        <p><a className='btn' href="https://twymanghoshal.com/">twymanghoshal.com</a></p>
        <Image />
    </Layout>
)

export default IndexPage
